import { createContext, useContext } from "react";
import { useI18n as useKeycloakifyI18n } from "keycloakify";

import en from "../../locale/en";
import ro from "../../locale/ro";

const I18N_CONTEXT = createContext(null);

// Resolves nested objects to single level objects with dot combined keys
// Example:
// {
//   a: {
//     b: {
//       c: ""
//     },
//     d: ""
//   }
// }
// =>
// {
//   "a.b.c": "",
//   "a.d": ""
// }
const flattenObject = (locale, parentKey) => {
  return Object.entries(locale).reduce((newLocale, [key, item]) => {
    if (typeof item === "object" && !Array.isArray(item))
      newLocale = {
        ...newLocale,
        ...flattenObject(item, parentKey ? `${parentKey}.${key}` : key),
      };
    else {
      newLocale[parentKey ? `${parentKey}.${key}` : key] = item;
    }

    return newLocale;
  }, {});
};

const transformLocale = (locale) => {
  return {
    ...locale.translations,
    ...flattenObject(locale.extra, "bapp"),
  };
};

export const I18nProvider = ({ children, kcContext }) => {
  const i18n = useKeycloakifyI18n({
    kcContext,
    extraMessages: {
      en: transformLocale(en),
      ro: transformLocale(ro),
    },
  });

  if (!i18n) return null;

  return <I18N_CONTEXT.Provider value={i18n}>{children}</I18N_CONTEXT.Provider>;
};

export const useI18n = () => {
  return useContext(I18N_CONTEXT);
};
