import { useEffect } from "react";

import { useBappMeta } from "../../utils/meta";

const InjectMetaStyles = () => {
  const meta = useBappMeta();

  useEffect(() => {
    const styleUrls = meta?.additionalStyleUrls ?? [];

    const linkElements = styleUrls.map((styleUrl) => {
      const linkElement = document.createElement("link");
      linkElement.setAttribute("rel", "stylesheet");
      linkElement.setAttribute("href", styleUrl);

      return linkElement;
    });

    linkElements.forEach((linkElement) =>
      document.head.appendChild(linkElement)
    );

    return () => linkElements.forEach((linkElement) => linkElement.remove());
  }, [meta]);

  return null;
};

export default InjectMetaStyles;
