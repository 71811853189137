import { getKcContext } from "keycloakify";

import { IS_DEV } from "./constants";
import { PAGE_NAMES } from "./pages";

const mockPageId = IS_DEV ? "login-idp-link-confirm.ftl" : undefined;

const mockClientId = "app-vue";

const extraMockData = {
  "login.ftl": {
    social: {
      displayInfo: true,
      providers: [
        {
          displayName: "Google",
          providerId: "google",
          loginUrl:
            "/realms/myrealm/broker/google/login?client_id=myclient&tab_id=nYnKo7AT-cU&session_code=pgolD4_hY09PDpwy9A5Z43eQN3aokmHW7zKtga5bpqM",
          iconClasses: "fa fa-google",
          alias: "google",
        },

        {
          displayName: "Facebook",
          providerId: "facebook",
          loginUrl:
            "/realms/myrealm/broker/facebook/login?client_id=myclient&tab_id=nYnKo7AT-cU&session_code=pgolD4_hY09PDpwy9A5Z43eQN3aokmHW7zKtga5bpqM",
          iconClasses: "fa fa-facebook",
          alias: "facebook",
        },

        {
          displayName: "Microsoft",
          providerId: "microsoft",
          loginUrl:
            "/realms/myrealm/broker/facebook/login?client_id=myclient&tab_id=nYnKo7AT-cU&session_code=pgolD4_hY09PDpwy9A5Z43eQN3aokmHW7zKtga5bpqM",
          iconClasses: "fa fa-microsoft",
          alias: "microsoft",
        },

        {
          displayName: "Apple",
          providerId: "apple",
          loginUrl:
            "/realms/myrealm/broker/facebook/login?client_id=myclient&tab_id=nYnKo7AT-cU&session_code=pgolD4_hY09PDpwy9A5Z43eQN3aokmHW7zKtga5bpqM",
          iconClasses: "fa fa-apple",
          alias: "apple",
        },

        {
          displayName: "Github",
          providerId: "github",
          loginUrl:
            "/realms/myrealm/broker/facebook/login?client_id=myclient&tab_id=nYnKo7AT-cU&session_code=pgolD4_hY09PDpwy9A5Z43eQN3aokmHW7zKtga5bpqM",
          iconClasses: "fa fa-github",
          alias: "github",
        },

        {
          displayName: "Gitlab",
          providerId: "gitlab",
          loginUrl:
            "/realms/myrealm/broker/facebook/login?client_id=myclient&tab_id=nYnKo7AT-cU&session_code=pgolD4_hY09PDpwy9A5Z43eQN3aokmHW7zKtga5bpqM",
          iconClasses: "fa fa-gitlab",
          alias: "gitlab",
        },
      ],
    },
  },

  "login-update-password.ftl": {
    isAppInitiatedAction: true,
  },
};

const mockData = PAGE_NAMES.map((pageId) => ({
  ...extraMockData[pageId],
  pageId,

  client: {
    ...extraMockData[pageId]?.client,
    clientId: mockClientId,
  },
}));

export const { kcContext } = getKcContext({
  mockPageId,
  mockData,
});

if (IS_DEV) console.log(kcContext);
