import { createContext, useContext, useEffect, useState } from "react";

const META_CONTEXT = createContext({});

export const MetaProvider = ({
  clientId = "internal",
  defaults = {},
  children,
}) => {
  const [meta, setMeta] = useState({});

  useEffect(() => {
    (async () => {
      const data = await getClientMeta();

      setMeta(
        Object.entries(defaults).reduce((acc, [key, value]) => {
          acc[key] = acc[key] || value;

          return acc;
        }, data)
      );
    })();
  }, [clientId, defaults]);

  const getClientMeta = async () => {
    try {
      const res = await fetch(
        `https://session.bapp.ro/login-banner?redirect_uri=${encodeURIComponent(
          window.location.href
        )}`
      );

      if (!res.ok) return {};

      return await res.json();
    } catch (error) {
      return {};
    }
  };

  return <META_CONTEXT.Provider value={meta}>{children}</META_CONTEXT.Provider>;
};

export const useBappMeta = () => {
  const meta = useContext(META_CONTEXT);

  return {
    ...meta,
  };
};
