const enLocale = {
  translations: {},

  extra: {
    general: {
      or: "Or",
      back: "Back",
      nextStep: "Next step",
      verify: "Verify",
      emailNotValid: "Email is not valid!",
      pleaseFillEmail: "Please fill your email!",
      pleaseFillPassword: "Please fill your password!",
      somethingUnexpectedHappened: "Something unexpected happened",
      signInWith: "Sign in with {0}",
    },

    register: {
      acceptTos:
        'I accept the <a href="https://bapp.ro/termeni-si-conditii/" target="_blank" rel="noopener noreferrer">terms and conditions</a>',
      inputCode: "Input the code received on your email",
      pleaseFillFirstName: "Please fill your first name!",
      pleaseFillLastName: "Please fill your last name!",
      passwordsMismatch: "Passwords do not match!",
      mustAcceptTos: "You must accept the terms and conditions!",
      verificationCodeLength: "Code must be 6 characters!",
      verificationCodeInvalid: "Code is invalid!",
    },

    forgotPassword: { resetPassword: "Reset password" },
  },
};

export default enLocale;
