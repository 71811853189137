import React from "react";

// Lazy pages
const Login = React.lazy(() => import("../pages/Login"));
const Register = React.lazy(() => import("../pages/Register"));
const LoginResetPassword = React.lazy(() =>
  import("../pages/LoginResetPassword")
);
const LoginUpdatePassword = React.lazy(() =>
  import("../pages/LoginUpdatePassword")
);
const LoginIdpLinkConfirm = React.lazy(() =>
  import("../pages/LoginIdpLinkConfirm")
);
const LoginPageExpired = React.lazy(() => import("../pages/LoginPageExpired"));
const LogoutConfirm = React.lazy(() => import("../pages/LogoutConfirm"));
const Info = React.lazy(() => import("../pages/Info"));
const Error = React.lazy(() => import("../pages/Error"));

// Page mapping
export const PAGES = {
  "login.ftl": <Login />,
  "register.ftl": <Register />,
  "login-reset-password.ftl": <LoginResetPassword />,
  "login-update-password.ftl": <LoginUpdatePassword />,
  "login-idp-link-confirm.ftl": <LoginIdpLinkConfirm />,
  "login-page-expired.ftl": <LoginPageExpired />,
  "logout-confirm.ftl": <LogoutConfirm />,
  "info.ftl": <Info />,
  "error.ftl": <Error />,
};

// Page id array
export const PAGE_NAMES = Object.keys(PAGES);
