import React, { Suspense } from "react";
import KcAppBase, { defaultKcProps } from "keycloakify";

import { kcContext } from "./utils/kcContext";
import { PAGES } from "./utils/pages";
import { I18nProvider } from "./services/i18n";
import Loading from "./components/Loading";
import { MetaProvider } from "./utils/meta";
import InjectMetaStyles from "./components/InjectMetaStyles";

import defaultBappLogo from "./assets/bapp-logo.svg";
import defaultBappCover from "./assets/bapp-cover.svg";
import defaultForeignCover from "./assets/foreign-cover.svg";

// Layouts
const BappLayout = React.lazy(() => import("./layouts/Bapp"));
const ForeignLayout = React.lazy(() => import("./layouts/Foreign"));

// Helpers
const wrapWithLoader = (element) => (
  <Suspense fallback={<Loading />}>{element}</Suspense>
);

const BAPP_CLIENT_IDS = ["app-vue", "app-vue-dev"];
const App = () => {
  const isBappPage =
    BAPP_CLIENT_IDS.indexOf(kcContext?.client?.clientId) !== -1 ||
    kcContext?.client?.clientId?.includes("bapp-") ||
    false;

  const getPageComponent = () => {
    return PAGES[kcContext.pageId] ?? null;
  };

  const pageContent = getPageComponent();

  const LayoutComponent = isBappPage ? BappLayout : ForeignLayout;
  const metaProviderProps = isBappPage
    ? {
        defaults: {
          logo: defaultBappLogo,
          cover: defaultBappCover,
        },
      }
    : {
        clientId: kcContext?.client?.clientId,
        defaults: {
          cover: defaultForeignCover,
        },
      };

  return (
    <I18nProvider kcContext={kcContext}>
      <MetaProvider {...metaProviderProps}>
        <InjectMetaStyles />

        <div className="h-full">
          {pageContent ? (
            wrapWithLoader(<LayoutComponent>{pageContent}</LayoutComponent>)
          ) : (
            <KcAppBase kcContext={kcContext} {...defaultKcProps} />
          )}
        </div>
      </MetaProvider>
    </I18nProvider>
  );
};

export default App;
