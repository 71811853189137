import React from "react";
import { Spin } from "antd";

const Loading = () => {
  return (
    <div className="flex items-center justify-center h-full gap-6">
      <Spin size="large" />

      <span className="text-lg font-bold text-slate-500">Loading...</span>
    </div>
  );
};

export default Loading;
