const roLocale = {
  translations: {},

  extra: {
    general: {
      or: "Sau",
      back: "Înapoi",
      nextStep: "Mai departe",
      verify: "Verifică",
      emailNotValid: "Emailul este invalid!",
      pleaseFillEmail: "Vă rugăm să introduceți emailul!",
      pleaseFillPassword: "Vă rugăm să introduceți parola!",
      somethingUnexpectedHappened: "Ați întampinat o eroare",
      signInWith: "Conectează-te cu {provider}",
    },

    register: {
      acceptTos:
        'Sunt de acord cu <a href="https://bapp.ro/termeni-si-conditii/" target="_blank" rel="noopener noreferrer">termenii și condițiile</a>',
      inputCode: "Introduceți codul primit pe email",
      pleaseFillFirstName: "Vă rugăm să introduceți prenumele!",
      pleaseFillLastName: "Vă rugăm să introduceți numele!",
      passwordsMismatch: "Parolele nu se potrivesc!",
      mustAcceptTos: "Trebuie sa acceptați termenii și condițiile!",
      verificationCodeLength: "Codul nu are 6 caractere!",
      verificationCodeInvalid: "Codul nu este valid!",
    },

    forgotPassword: {
      resetPassword: "Resetează",
    },
  },
};

export default roLocale;
